body {
	font-size: 14px; // overriding the foundation font-size
	word-wrap : break-word;
	overflow-wrap : break-word;
	[lang="ja"] & {
		font-family: $font-family-japanese;
	}
	.english-font { // This can be used when we want to use English font on the ja site
		font-family: $font-family-sans-serif;
	}
}
p:last-child {
	margin-bottom: 0;
}
.touch-space {
	@include touch-space;
}
.tweet-button-wrapper {
	@media #{$medium-up} {
	}
}
.tweet-match {
	margin-bottom: $space-s;

	@media #{$medium-up} {
		float: left;
		margin: 0;

		.button {
			height: 34px;
		}
	}

}
.sidebar {
	padding-bottom: 20px;
}

//the main content area
.content-area {

	&.bordered {
		border-left: 1px solid $grey-lighter;
		border-right: 1px solid $grey-lighter;
	}

	&.border-left {
		border-left: 1px solid $grey-lighter;
	}
	&.border-right {
		border-right: 1px solid $grey-lighter;
	}

	.site-main {
		$post-padding: 20px;

		.entry-header {
			.entry-title {
				margin-bottom: 10px;
				color: $body-font-color;

				&.with-meta {
					margin-bottom: 0;
				}
			}
		}

		h1, h2, h3, h4, h5, h6 {
			margin-bottom: 10px;
		}

		.entry-meta {
			margin-bottom: 10px;

			span {
				&.expand {
					display: block;
				}
				&.posted-on {
					color: $text-mute-color;
				}
				&.post-author {
					a {
						margin-right: 10px;
						color: $body-font-color;
						font-weight: bold;
						text-decoration: underline;
					}
				}
			}
		}

		.post, .page {
			.post-inner {
				border: 1px solid $grey-lighter;
				padding: $post-padding;
			}
			margin-bottom: 30px;

			.entry-content {
				@include clearfix;

				blockquote {
					p {
						margin: 0;
					}
				}
				p {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.entry-footer {
				padding: ($post-padding / 2) $post-padding;
				border-right: 1px solid $grey-lighter;
				border-bottom: 1px solid $grey-lighter;
				border-left: 1px solid $grey-lighter;
				@include clearfix();

				span {
					margin-right: 10px;
				}

				.button {
					margin-bottom: 0;
				}

				&.entry-single {
					border: none;
					padding: 5px 0;
				}
			}
		}

		.news-announcements {
			.entry-title {
				color: $primary-color;
			}
			.post {
				border-bottom: 1px solid $grey-lighter;
				padding-bottom: 30px;
			}
		}
	}

	.page-title {
		margin-bottom: 20px;
		color: $body-font-color;
		.vcard {
			border: none;
			padding: 0;
			margin: 0;
		}
	}

	.login-message {
		margin-bottom: 20px;
	}

	.read-more {
		.read-more-link {
			text-decoration: underline;
		}
	}

	.regulation-list {
		list-style: none;
		margin: 0 0 40px;
		padding-left: 40px;
		counter-reset: itemNum;
		li {
			position: relative;
			counter-increment: itemNum;			
			&:before {
				position: absolute;
				left: -40px;
				top: 0;
				content: "(" counter(itemNum) ")";
			}
		}

	}
}

.checklist-style {
	list-style-image: url(../images/checklist.png);
}

.form-section fieldset.blue-fieldset {
	border: 2px solid #5684d8;
}
