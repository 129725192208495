// page layout classes
.page, .single, .blog, .archive, .search-results, .error404 {
	.site-content {
		@include grid-row();
	}
	.site-content {
		padding-top: 30px;
		padding-bottom: 30px;

		.content-area {
			@include grid-column(12);

			@media #{$large-up} {
				&.two-column {
					@include grid-column(8);
				}

				&.three-column {
					@include grid-column(6);
				}
			}
			
			@media #{$xlarge-up} {
				&.two-column {
					@include grid-column(9);
				}

				&.three-column {
					@include grid-column(6);
				}
			}
		}

		.sidebar {
			@include grid-column(12);

			@media #{$large-up} {
				@include grid-column(4);

				&.three-column {
					@include grid-column(3);
				}
			}

			@media #{$xlarge-up} {
				@include grid-column(3);
			}
		}
	}
}
.home {
	.site-content {
		.content-area {
			padding: 0;
		}
	}
}
.thankyou-page {
	@include grid-row();

	.site-main {
		@include grid-column(12);

		@media #{$medium-up} {
			@include grid-column($columns: 10, $offset: 1);
		}

		@media #{$large-up} {
			@include grid-column($columns: 8, $offset: 2);
		}

		@media #{$xlarge-up} {
			@include grid-column($columns: 6, $offset: 3);
		}
	}
}
.container {
	@include grid-row();
	padding-left: ($column-gutter / 2);
	padding-right: ($column-gutter / 2);
}
.entry-header {
	@include grid-column(12);
	margin-bottom: $space-s;
}
