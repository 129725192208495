@mixin overlay($position: fixed) {
	position: $position;
	width: 100%;
	height: 100%;
	visibility: hidden;
	top: 0;
	left: 0;
	z-index: 1000;
	opacity: 0;
	background: rgba(255, 255, 255, 0.7);
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

// Gradient
@mixin vertical-gradient ($top:$light-blue-gradient-top, $bottom:$light-blue-gradient-bottom){
	background: $top;
	background: -webkit-linear-gradient(
		top,
		$top 0%,
		$bottom 100%
	);
	background: linear-gradient(
		to bottom,
		$top 0%,
		$bottom 100%
	);
}
@mixin horizontal-gradient ($left:$light-blue-gradient-top, $right:$light-blue-gradient-bottom){
	background: $left;
	background: -webkit-linear-gradient(
		$left 0%,
		$right 100%
	);
	background: linear-gradient(
		$left 0%,
		$right 100%
	);
}


// Visibility
@mixin visible {
	visibility: visible;
	filter: alpha(opacity=1);
	opacity: 1;
}
@mixin invisible {
	visibility: hidden;
	filter: alpha(opacity=0);
	opacity: 0;
}

// Opacity
@mixin opacity($value) {
	opacity: $value;
	$opacityIE: $value * 100;
	filter: alpha(opacity=$opacityIE);
	zoom:1;
}

// Touch space settings
@mixin touch-space {
	-ms-touch-action : none;
	touch-action : none;
}
