//3rd party css
@import 'third-party/normalize';
@import 'third-party/jquery-ui';
@import 'font-awesome';
@import 'third-party/slick';
@import 'third-party/chosen';
@import 'third-party/tags-input';

//foundation
@import 'foundation';

//Custom styles
@import 'partials/mixins';
@import 'partials/components';
@import 'partials/buttons';
@import 'partials/header';
@import 'partials/home';
@import 'partials/structure';
@import 'partials/main-content';
@import 'partials/helpers';
@import 'partials/footer';
@import 'partials/forms';
@import 'partials/resume';
@import 'partials/search';
