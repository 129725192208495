// Custom buttons

@mixin light-blue-btn($full-width: false) {
	// having a mixin for the style in case you don't want to add a class to an element

	@include button(
		$bg: $light-blue,
		$radius: $global-radius,
		$full-width: $full-width
	);

	&.gradient {
		@include vertical-gradient($light-blue-gradient-top, $light-blue-gradient-bottom);
		@include opacity(1);
		@include single-transition;

		&:hover {
			@include opacity(.92);
		}
	}

	&.small {
		@include button(
			$bg: $light-blue,
			$padding: $button-sml,
			$radius: $global-radius
		);

	    @media screen and (max-width: $small-breakpoint) {
	        &:first-of-type:not(:last-of-type) {
	        	margin-bottom: 5px; // to avoid stacking buttons without spacing	
	        }
	    }

	}
}

.light-blue-btn {
	@include light-blue-btn;
}

.default-btn {
	@include button(
		$bg: false
	);
}