.search {
    @include grid-column(12);
}
.search-table {
    width: 100%;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}
.search-box {
    background-color: $grey-lightest;
    padding-top: $space-s;
    padding-bottom: $space-s;

    .search-form {
        @include grid-row();
    }

    .search-fields {

        @media #{$medium-up} {
            @include grid-column(8);
        }

        .field-row {
            @include grid-row();
        }

        .search-input {
            @include grid-column($columns: 10, $collapse: true);
        }        

        .search-submit {
            @include grid-column($columns: 2, $collapse: true);
        }
    }

    .search-meta {
         @media #{$medium-up} {
            @include grid-column(4);
        }
    }

    input[type=text] {
        margin-bottom: 0;
    }
}