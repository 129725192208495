.site-content {
	margin-bottom: $column-gutter;
}
sup {
	color: $red;
}
input[type="submit"] {
	border-radius: $global-radius; // fix for the button not getting radius
}

input[type="number"] {
	-moz-appearance: textfield;
	-webkit-appearance: none;
}

.field-group {
	@include grid-row();
	margin-bottom: 20px;
	&:last-of-type {
		margin-bottom: 0;
	}

	.panel {
		@include grid-column(12);
		margin: 0;
	}

	.full-width-label {
		@include grid-column(12);
	}
	.radio-label {
		font-size: 12px
	}

	.field, .full-width-field {
		@include grid-column(12);

		input[type=text],
		input[type=email],
		input[type=tel],
		input[type=password],
		input[type=radio] {
			margin-bottom: 0;
		}
		&.error {
			input {
				border-color: $red;
			}
		}
	}

	.help {
		@include grid-column(12);

		font-size: 13px;
		color: $red;
	}

	.inline-input {
		display: inline-block;
		width: 150px;
	}

	.inline-label {
		display: inline-block;
	}

	.terms-conditions {
		overflow: scroll;
		max-height: 142px;
		margin-bottom: 5px;
		padding: 10px;
		border: $fieldset-border-width $fieldset-border-style $fieldset-border-color;
	}

	&.inline {
		margin-bottom: 0;

		&> label, .field {
			@include grid-column(12);
		}

		.field {
			margin-bottom: 5px;
		}
	}

	@media #{$xlarge-up} {
		.field {
			@include grid-column(6);
		}

		.field-nest {
			@include grid-column($columns: 9, $collapse: true);
		}

		.help {
			@include grid-column(6);
		}

		&.inline {
			&>label {
				@include grid-column(3);


				span {
					display: inline-block;
					padding-top: 8px;
					padding-bottom: 8px;
				}
			}
			.field {
				@include grid-column(9);

				&.half {
					@include grid-column(5);
				}
			}
			.help {
				@include grid-column($columns: 9, $offset: 3);
			}
		}
	}
}

fieldset {
	position: relative;

	.panel {
		@include grid-row();

		.error {
			@include grid-column(12);

			li {
				color: $red;
			}
		}
	}

	.form-col, .form-one-column, .form-two-columns {
		&.first {
			padding-top: 0;
		}
	}
}

.form-inline {
	label {
		display: inline-block;
		font-weight: normal;
	}

	.cell {
		height: $input-height;
	}
}
.input-attach {
	$btn-width: 80px;

	position: relative;

	input[type=text], input[type=email] {
		padding-right: $btn-width + 10px;
	}

	button {
		position: absolute;
		width: $btn-width;
		top: 1px;
		right: 0;
	}
}

.user-form {
	max-width: 300px;
}

.submission-message {
	color: $green-light;
	&.submission-error {
		color: $red;
	}
}

.form-tabs {
	@include clearfix;
	list-style: none;
	margin: 0;
	z-index: 10;
	li {
		margin-bottom: 2px;
		span {
			position: relative;
			display: block;
			@include vertical-gradient($lightest-blue-gradient-top, $lightest-blue-gradient-bottom);
			padding: 5px 25px 5px 10px;
			color: $white;
		}
		a, .tab-txt {
			position: relative;
			display: block;
			padding: 5px 25px 5px 10px;
			background: $grey-lightest;
			color: $grey-dark;
			@include single-transition(opacity);
			&:hover {
				@include opacity(0.7);
			}
		}
		&.current a {
			@include vertical-gradient($lightest-blue-gradient-top, $lightest-blue-gradient-bottom);
			color: $white;
			cursor: default;
			&:hover {
				@include opacity(1);
			}
		}
	}
	&.fixed {
		background: $blue;
		@media screen and (max-width:#{upper-bound($medium-range)}) {
			left: 0;
			top: 0 !important;
			background: $blue;
			width: 100vw !important;
			padding: 5px;
			li {
				margin: 0 2px 2px 0;
				float: left;
				span {
					padding: 3px;
					font-size: 10px;
				}
				a, .tab-txt {
					padding: 3px;
					font-size: 10px;
				}
			}
		}
		@media #{$large-up} {
			background: $white;
		}
	}
}

.form-messages {
	color: $success-color;
	margin-bottom: 0;
	
	&.bad {
		color: $red;

		&:before {
			@include fa-icon();
			content: $fa-var-exclamation-triangle;
			padding-right: 5px;
		}
	}
}
.course-row {
	&.paid {
		color: $text-mute-color;

		label {
			color: $text-mute-color;
			cursor: default;
		}

		td {
			color: $text-mute-color
		}

		span {
			&.green {
				color: $green;
			}
		}
	}
}
.bump-right {
	margin-right: $space-xs;
}
input {
	&.input-date {
		width: 60px;
		display: inline-block;
		margin-right: $space-xs;
	}
}


// Resume Form
.form-slide {
	padding-bottom: 50px;
	button {
		outline: 0;
	}
}
.two-cols-form fieldset {
	margin: 0 0 $space;
	padding: 0 0 $space;
}

.repeatable-field {
	margin-bottom: $space;
	fieldset {
		margin: 0;
	}
	button {
		outline: 0;
	}
	.repeatable-add {
		margin-top: $space-s;
	}
	.repeatable-remove {
		position: absolute;
		right: -1px;
		top: -1px;
		background: $grey-light;
	}
	.remove-fieldset {
		position: absolute;
		right: -1px;
		top: -1px;
		background: $grey-light;
	}
}
.button.repeatables {
	background: $green-dark;
}

.form-border-bottom {
	position: relative;
	padding-bottom: $space;
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: $space;
		width: -moz-calc(100% - 40px);
		width: -webkit-calc(100% - 40px);
		width: calc(100% - 40px);
		height: 1px;
		background: $sub-section-border;
	}
}

.form-one-column {
	@include grid-row();
	.form-col {
		@include grid-column(12);
	}
}

.form-two-columns {
	@include grid-row();
	.form-col {
		@include grid-column(12);
		@media #{$large-up} {
			@include grid-column(6);
		}
	}
}

.form-col {
	padding-top: $space;
	select {
		width: 100%;
		margin-bottom: 0;
	}
	input {
		margin-bottom: 0;
	}
	.input-height {
		min-height: 37px;
	}
	textarea {
		height: 120px;
		margin-bottom: 0;
	}
	.help {
		color: $red;
	}

	&.tight {
		padding-top: $space-s;
	}
}

.form-confirmation .error {
	color: $red;
}

.form-prev {
	outline: 0;
	@include single-transition;
	&:before {
		margin-right: 10px;
		font-family: FontAwesome;
		content: "\f104";
	}
}
.form-next {
	outline: 0;
	@include single-transition;
	&:after {
		margin-left: 10px;
		font-family: FontAwesome;
		content: "\f105";
	}
}

.form-slider-arrows {
	@include clearfix;
	.slick-prev {
		outline: 0;
		float: left;
		@include single-transition;
		&:before {
			margin-right: 10px;
			font-family: FontAwesome;
			content: "\f104";
		}
	}
	.slick-next {
		outline: 0;
		float: right;
		@include single-transition;
		&:after {
			margin-left: 10px;
			font-family: FontAwesome;
			content: "\f105";
		}
	}
	.slick-disabled {
		visibility: hidden;
		opacity: 0;
	}
}
.slick-slide {
	outline: 0;
}
.panel {
	.header {
		background-color: $grey-lightest;
		color: #444;
		padding: $space-s;
		border-width: 1px;
		border-color: #ddd;
		border-style: solid;
		font-weight: bold;
		font-size: 1rem;

		label, input[type=checkbox] {
			margin: 0;
		}
	}

	fieldset {
		margin-top: 0;
		border-top: none;

		&.no-header {
			border-top: 1px solid $grey-lightest;
		}
	}
}

// Magellan fix
[data-magellan-expedition-clone] {
	opacity: 0;
}

// Datepicker
input.datepicker[readonly] {
	background-color: $input-bg-color;
}

.sub-header {
	margin-bottom: $space-s;
	color: $grey-dark;
}
.date-inline {
	.year, .month, .day {
		display: inline-block;
		min-width: 100px;
	}

	.month, .day {
		margin-left: 5px;
	}
}
.current-resume {
	margin-bottom: $space;

	a {
		color: $green;
		font-size: 16px;

		i {
			vertical-align: middle;
		}
	}
}
.corp-login {
	max-width: $medium-breakpoint;
	margin: 0 auto;
}
.login {
	max-width: $small-breakpoint;
	margin: 0 auto;
}

.consent-box {
    overflow: scroll;
    max-height: 123px;
    margin-bottom: $space-xs;
	padding: $space-s;
	border: 1px solid $grey-lighter;

	h3, h4 {
		margin-bottom: $space-s;
	}
	
	ol {
		li {
			margin-bottom: $space-s;
		}
	}
}

.consent-notes {
	font-size: 12px;
	margin-top: $space;
	margin-bottom: 0;
}
.expl {
	ul {
		margin-bottom: 0;
	}
}

.complete-check {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -6px;
	font-size: 12px;
	color: $green-light;
	span & {
		color: $white;
	}
}
