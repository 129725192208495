.logo {
	text-align: center;
	@media #{$medium-up} {
		float: left;
		text-align: left;
		padding: $space-s 0;

		&>a {
			display: inline-block;
			max-width: 280px;
		}
	}
	.governance-search {
		display: none;
		@media #{$large-up} {
			display: inline-block;
		}
	}
}

.site-tagline-2{
	@media screen and (max-width: $medium-breakpoint) {
		display: flex;
		padding: 0px;
	}
}
.nav-bar {
	background-color: $grey-lightest;
}
.left-side, .right-side {
	float: none;
}
div#captcha-contact{
  margin: -25px 0 13px 0;
}
@media #{$medium-up} {
	.left-side {
		float: left;
	}
	.right-side {
		float: right;
	}
}
.main-nav {
	margin: 0;
	list-style-type: none;

	li {
		display: block;
		vertical-align: middle;
		padding-right: $space-s;
		padding-left: $space-s;
		height: 100%;
		text-align: left;

		a {
			color: $blue-darker;
		}
	}

	@media #{$medium-up} {
		padding-top: $space-xs;
		padding-bottom: $space-xs;

		li {
			display: inline-block;
			text-align: center;
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}
.language-switcher {
	float: left;
	padding: 3px;
	margin: 10px 0;
	text-align: center;

	img {
		width: 20px;
		margin: 0 3px;
	}

	a {
		&.active {
			img {
				opacity: 0.5;
			}
		}
	}

	@media #{$medium-up} {
		float: right;
		margin: 0 0 5px 0;
		background-color: $grey-lightest;
		margin: 0 $space-s 0 0;
	}
}
.bdti-header-logo {
	clear: both;
	float: right;
	width: 80px;
	margin: 20px 10px 0 0;
	@media screen and (max-width: $medium-breakpoint) {
		display: none;
	}
}

.site-header {
	position: relative;
	z-index: 10;
	box-shadow: 0 0 6px rgba(0, 0, 0, .2);
	@media #{$large-up} {
		box-shadow: none;
	}
}

.menu-button {
	position: relative;
	z-index: 10;
	line-height: 1.2;
	float: right;
	margin: 10px 0;
	padding: 10px;
	border-radius: $global-radius;
	background: $blue-darker;
	color: $white;
	font-size: 14px;
	text-transform: uppercase;
	cursor: pointer;
	@media #{$medium-up} {
		clear: right;
		margin: 20px 10px 0 0;
	}
	@media #{$large-up} {
		display: none;
	}
	&:after {
		@include fa-icon();
		padding-left: 5px;
		content: "\f0c9";
	}
}

.lower-buttons {
	display: none;
}


/* Mobile Navigation */

@media screen and (max-width: $medium-breakpoint) {
	.main-navigation {
		@include invisible;
		position: absolute;
		left: 3%;
		top: 100%;
		width: 94%;
		margin-top: 2px;
		margin-bottom: 10px;
		border-radius: $global-radius;
		background-color: $blue;
		box-shadow: 0 2px 7px rgba(0, 0, 0, 0.7);
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
		@include single-transition;
		&.nav-open {
			@include visible;
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
		&:before {
			position: absolute;
			top: -36px;
			right: 18px;
			display: block;
			width: 0;
			height: 0;
			content: "";
			border: 18px solid transparent;
			border-bottom-color: $blue-darker;
		}
		ul {
			list-style: none;
			margin: 0;
		}
		.container {
			padding: 0;
		}
		li > a, li > span {
			display: block;
			position: relative;
			line-height: 1.6;
			padding: 10px;
			color: $white;
			font-size: 14px;
			text-decoration: underline;
			&:before {
				@include fa-icon();
				display: block;
				position: absolute;
				right: 100%;
				top: 10px;
				content: "\f105";
				font-size: 20px;
				text-decoration: none;
			}
		}

		// Top level
		.nav > ul {
			padding: 0;
			> li {
				height: 51px;
				overflow: hidden;
				padding-bottom: 10px;
				@include single-transition;
				&:first-child {
					height: 50px;
				}
				// &:last-child {
				//     border-bottom-left-radius: $global-radius;
				//     border-bottom-right-radius: $global-radius;
				// }
				> a, > span {
					margin-top: 5px;
					padding-left: 40px;
					&:before {
						left: 24px;
					}
				}
			}
		}

		// Second level and below
		ul ul {
			padding-left: 20px;
		}
		.nav > ul > li > ul { // second level
			padding-left: 50px;
		}

		// Cloned parent text
		.cloned-parent {
			position: relative;
			line-height: 1.5;
			padding: 14px 20px;
			border-top: 1px solid $light-line;
			border-bottom: 1px solid $dark-line;
			background: $blue-darker;
			color: $white;
			font-size: 14px;
			cursor: pointer;
			&:after {
				position: absolute;
				right: 15px;
				top: 14px;
				@include fa-icon();
				content: "\f077";
				font-size: 20px;
				color: $light-blue;
				-webkit-transform: rotate(180deg);
				transform: rotate(180deg);
				-webkit-transform-origin: center;
				transform-origin: center;
				@include single-transition;
			}
		}
		.li-open .cloned-parent:after {
			transform: rotate(0deg);
		}

		li:first-child .cloned-parent {
			margin-top: 0;
			border-top: none;
			border-top-left-radius: $global-radius;
			border-top-right-radius: $global-radius;
		}
	}

	.lower-buttons {
		text-align: center;
		padding: $space-s $space-xs;
	}
	.close-button {
		padding: 10px 20px;
		border-top: 1px solid $light-line;
		border-bottom-left-radius: $global-radius;
		border-bottom-right-radius: $global-radius;
		background: $blue-dark;
		color: $white;
		cursor: pointer;
		text-align: center;
		text-transform: uppercase;
	}
	.desktop-component {
		display: none;
	}
}

@media screen and (max-width: $small-breakpoint) {
	.main-navigation {
		margin-top: 12px;
	}
}

/* Tablet and Desktop Navigation */

@media #{$large-up} {
	.main-navigation {
		background-color: $blue-darker;
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}
		li > a, li > span {
			display: block;
			position: relative;
			z-index: 10;
			line-height: 1.2;
			color: #fff;
			font-size: 14px;
			text-decoration: none;
			background: $blue-darker;
			@include single-transition;
			&:hover {
				background: $light-line;
			}
		}
		.nav > ul {
			@include clearfix;
			border-left: 1px solid $dark-line;
			border-right: 1px solid $light-line;
			> li {
				position: relative;
				float: left;
				border-right: 1px solid $dark-line;
				border-left: 1px solid $light-line;
				> a, > span  {
					padding: 16px 23px;
					@include touch-space;
				}

				&.current-menu-item,
				&.current-menu-ancestor {
					// > a {
					//     background: $light-line;
					// }
				}
			}
		}
	}
	.nav li ul {
		visibility: hidden;
		position: absolute;
		left: 0;
		top: 100%;
		@include opacity(0);
		@include single-transition;
		width: 100%;
		li {
			position: relative;
			width: 230px;
			border-top: 1px solid $light-line;
			border-bottom: 1px solid $dark-line;
			a, span {
				line-height: 1.2;
				padding: 10px;
				font-size: 14px;
				@include single-transition;
				&:hover {
					background: $light-line;
				}
			}
			&:first-of-type {
				border-top: none;
				border-top-left-radius: $global-radius;
				border-top-right-radius: $global-radius;
				> a, > span {
					border-top-left-radius: $global-radius;
					border-top-right-radius: $global-radius;
				}
			}
			&:last-of-type {
				border-bottom: none;
				border-bottom-left-radius: $global-radius;
				border-bottom-right-radius: $global-radius;
				> a, > span {
					border-bottom-left-radius: $global-radius;
					border-bottom-right-radius: $global-radius;
				}
			}
		}
	}
	.nav.non-touch-nav li:hover,
	.nav .child-nav-shown {
		> ul {
			visibility: visible;
			@include opacity(1);
		}
	}

	.nav > ul > li > ul {
		padding-top: 15px;
		&:before {
			position: absolute;
			left: 50%;
			top: -21px;
			@include fa-icon();
			margin-left: -18px;
			content: "\f0d8";
			color: $blue-darker;
			font-size: 62px;
			line-height: 1;
			@include single-transition;
		}
	}
	.nav.non-touch-nav > ul > li:hover > ul,
	.nav .child-nav-shown > ul {
		padding-top: 20px;
		&:before {
			top: -16px;
		}
	}

	.nav > ul > li > ul > li > ul {
		left: 100%;
		top: 0;
		padding-left: 15px;
		&:before {
			position: absolute;
			left: 3px;
			top: -13px;
			@include fa-icon();
			content: "\f0d9";
			color: $blue-darker;
			font-size: 62px;
			line-height: 1;
			@include single-transition;
		}
	}
	.nav.non-touch-nav > ul > li > ul > li:hover > ul {
		padding-left: 20px;
		&:before {
			left: 8px;
		}
	}

	.nav > ul > li:last-of-type > ul > li > ul {
		left: auto;
		right: 100%;
		top: 0;
		width: auto;
		padding-left: 0;
		padding-right: 15px;
		&:before {
			position: absolute;
			left: auto;
			right: 3px;
			top: -13px;
			@include fa-icon();
			content: "\f0da";
			color: $blue-darker;
			font-size: 62px;
			line-height: 1;
			@include single-transition;
		}
	}
	.nav.non-touch-nav > ul > li:last-of-type > ul > li:hover > ul {
		padding-right: 20px;
		&:before {
			left: auto;
			right: 8px;
		}
	}
	.mobile-component {
		display: none;
	}
	.touch-component {
		display: none;
		.touch & {
			display: block;
		}
	}
}
