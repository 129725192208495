.site-footer {
	overflow: hidden;
	padding: 10px 15px;
	background-color: $grey-lighter;

	.searchbox {
		@include searchbox-style($white, $grey-light, $light-line, $grey-dark, $grey-dark);
		margin: 5px 0 10px -5px;
		@media #{$medium-up} {
			display: none;
		}
	}
	.site-info {
		@media #{$medium-up} {
			position: relative;
			left: 50%;
			float: left;
		}

		> div {
			@media #{$medium-up} {
				float: left;
				position: relative;
				right: 50%;
			}
		}
	}
	.footer-logo {
		display: none;
		@media #{$medium-up} {
			display: block;
			float: left;
			position: relative;
			right: 50%;
		}
	}
	.mobile-copyright {
		margin-top: 15px;
		text-align: center;
		font-size: 12px;
		a {
			margin-right: 5px;
		}
		@media #{$medium-up} {
			display: none;
		}
	}
	.footer-list {
		margin: 0;
		padding: 5px 0 0;
		list-style-type: none;
		@include clearfix;
		li {
			float: left;
			width: 50%;
			margin-bottom: 5px;
			@media #{$small-only} {
				&:nth-of-type(odd) {
					clear: both;
				}				
			}
			@media #{$medium-up} {
				width: auto;
				border-right: 1px solid $grey_darker;
				&:last-child {
					border-right: 0;
				}
			}
			a {
				display: block;
				line-height: 1.2;
				padding: 5px 0;
				color: $grey_darker;
				font-size: 12px;
				font-weight: bold;
				text-decoration: none;
				@media #{$medium-up} {
					padding: 0 10px;
					text-decoration: underline;
				}
			}
		}
	}
}

