div.tagsinput { 
    border: 1px solid #ddd; 
    background: whitesmoke; 
    padding: 8px; 
    width: 100%; 
    height: 126px; 
    overflow-y: auto;
}
div.tagsinput span.tag { 
    border: 1px solid #ddd; 
    border-radius: 2px;
    display: block; 
    float: left; 
    padding: 5px; 
    text-decoration: none; 
    background: #fff; 
    color: #445; 
    margin-right: 5px; 
    margin-bottom: 5px;
    font-size: 12px;
    height: 30px;
}
div.tagsinput span.tag a {
    font-weight: bold; 
    color: #82ad2b; 
    text-decoration: none; 
    font-size: 12px;  
} 
div.tagsinput input {
    width: 110px; 
    margin: 0px; 
    font-size: 12px; 
    border: 1px solid #ddd; 
    padding: 5px; 
    background: #fff; 
    color: #000; 
    outline: 0px;  
    margin-right: 5px; 
    margin-bottom: 5px;
    height: 30px;

    &:focus {
        border-color: darken(#ddd, 5%);
        background: #fff;
    } 
}
div.tagsinput div { 
    display: block; 
    float: left; 
} 
.tags_clear { 
    clear: both; 
    width: 100%; 
    height: 0px; 
}
.not_valid {
    background: #FBD8DB !important; 
    color: #90111A !important;
}
.tags-limit-reached {
    [id$="_addTag"] {
        display: none !important;
    }
}

