//simple independent helper classes

@media #{$medium-up} {
	.medium-left {
		float: left;
	}
	.medium-right {
		float: right;
	}
}
@media #{$large-up} {
	.large-left {
		float: left;
	}
	.large-right {
		float: right;
	}
}

.large-inline-list {
	list-style: none;
	padding: 0;
	margin: 0;
	@media #{$large-up} {
		@include inline-list;
	}
}
.xlarge-inline-list {
	list-style: none;
	padding: 0;
	margin: 0;
	@media #{$xlarge-up} {
		@include inline-list;
	}
}
.large-inline-list, .inline-list, .xlarge-inline-list {
	> li > .inline {
		display: inline;
	}
	> li > .inline-block {
		display: inline-block;
	}
}
input.inline-input {
	display: inline-block;
	width: 100px;
	height: auto;
	margin-bottom: 0;
	padding: 3px;
}

.lower-latin {
	list-style-type: lower-latin;
}

.screen-reader-text {
	display: none;
}
.underline {
	text-decoration: underline;
}

.panel {
	margin-bottom: 20px;
	@include clearfix;
}
.large-panel {
	margin-bottom: 40px;
	@include clearfix;
}
.panel-xs {
	margin-bottom: $space-xs;
}
.panel-s {
	margin-bottom: $space-s;
}
.panel-m {
	margin-bottom: $space;
}
.panel-l {
	margin-bottom: $space-l;
}
.panel-xl {
	margin-bottom: $space-xl;
}

.tbl {
	display: table;
	width: 100%;
}
.cell {
	display: table-cell;
	vertical-align: middle;
}
.input-height {
	height: $input-height;
}

//just test, taking a page from semantic ui
.split-row {
	@include grid-row();

	.column {
		@include grid-column(12);
		margin-bottom: 20px;
	}

	@media #{$medium-up} {
		&.two {
			.column {
				@include grid-column(6);
				margin-bottom: 0;
			}
		}
		&.three {
			.column {
				@include grid-column(4);
				margin-bottom: 0;
			}
		}
		&.four {
			.column {
				@include grid-column(3);
				margin-bottom: 0;
			}
		}
	}

	@media #{$large-up} {
		&.large-two {
			.column {
				@include grid-column(6);
				margin-bottom: 0;
			}
		}
		&.large-three {
			.column {
				@include grid-column(4);
				margin-bottom: 0;
			}
		}
		&.large-four {
			.column {
				@include grid-column(3);
				margin-bottom: 0;
			}
		}
	}
}

.embed {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
}

.embed iframe, .embed object, .embed embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}
table {
	&.full-width {
		width: 100%;
	}
}

#grid {
	.row {
		background-color: #ddd;
		margin-bottom: 20px;

		.gitem {
			height: 30px;
			background-color: #999;
			opacity: 0.8;
			color: #fff;
			line-height: 30px;
			text-align: center;
		}
	}
}
.featured-thumb {
	float: left;
	margin: 0 $space-ss $space-ss 0;
}
.red {
	color: $red;
}
.sidenote {
	color: $grey;
	font-size: 13px;
}

.grey-border-box {
	margin-bottom: $space;	
	padding: $space;
	border: 1px solid $grey-lighter;
}
.append {
	font-size: 13px;
	margin-right: $space-xs;
}

.table-cols {
	display: table;
	table-layout: fixed;
	width: 100%;
	.table-col {
		display: table-cell;
		vertical-align: top;
	}
	.gutter {
		display: table-cell;
		vertical-align: top;
	}
}
.nomargin {
	margin-bottom: 0!important;
}
.labels {
	margin-bottom: $space-xs;
}

.clear {
	clear: both;
}
a {
	&.underline {
		text-decoration: underline;
	}
}
.inline-block {
	display: inline-block;
}

// Text colors
.text-primary { color: $primary-color; }
.text-secondary { color: $secondary-color; }
.text-alert { color: $alert-color; }
.text-success { color: $success-color; }
.text-warning { color: $warning-color; }
.text-info { color: $info-color; }
