$aside-width: 220px;

.resume-container {
    max-width: $large-breakpoint;
    margin: $space auto;
    padding-right: ($column-gutter / 2);
    padding-left: ($column-gutter / 2);
}
.resume {
    border: 1px solid $grey-light;
    padding: $space;

    h1, h2, h3, h4, h5, h6 {
        color: $grey-darker;

        small {
            font-size: 0.85em;
            color: lighten($grey-darker, 20%);
            margin-left: $space;
        }
    }
    

    .header {
        margin-bottom: $space;
    }

    .body {
        @include clearfix();

        .aside {
            width: $aside-width;
            float: left;
        }

        .main {
            margin-left: $aside-width + $space-s;

            .section {
                margin-bottom: $space;

                &>h5 {
                    border-left: 4px solid $primary-color;
                    padding-left: 6px;
                    margin: 0;
                }

                .inner {
                    padding: $space-s;

                    p {
                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }
            .sub-section {
                padding: $space-s 0;
                border-bottom: 1px dashed $grey-lighter;
                p {
                    margin-bottom: $space-xs;
                }

                &:first-child {
                    padding-top: $space-xs;
                }
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
        }

        &.no-sidebar {
            .main {
                margin: 0;
            }
        }
    }
}
.resume-controls {
    @include clearfix();
    padding: $space-s 0;
    font-size: 16px;
}
.stripped {
    margin: 0;
    padding: 0;
    list-style-type: none;
}