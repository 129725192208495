.front-page {
	.page-title {
		padding-left: $column-gutter / 2;
		padding-right: $column-gutter / 2;
		margin-bottom: 20px;
	}
	.bdit-director-bank {
		display: block;
		margin-bottom: 15px;
		color: $grey-dark;
		.column {
			.column-inner {
				display: block;
				border: 1px solid $grey-light;
				background: $grey-lightest;
				color: $grey-dark;
				@include single-transition(opacity);
				&:hover {
					opacity: .8;
				}
			}
			&:first-of-type {

			}
			&:last-of-type {
				.column-inner {
					background: $blue;
					color: $white;
				}
			}
		}
		.top-text {
			padding: 20px 10px;
			border-bottom: 1px solid $grey-light;
			font-size: 24px;
			font-weight: bold;
			text-align: center;
		}
		.middle-text {
			padding: 20px;
		}
		.large-text {
			margin-bottom: 15px;
			font-size: 18px;
			font-weight: bold;
			text-align: center;
		}
		.down-arrow {
			text-align: center;
		}
		.bottom-image {
			img {
				width: 100%;
			}
		}
	}
	.bdti-links {
		list-style: none;
		margin: 0;
		padding-left: $column-gutter / 2;
		padding-right: $column-gutter / 2;
		> li {
			margin-bottom: 15px;
			> a {
				display: block;
				padding: $column-gutter / 2;
				border: 1px solid $grey-light;
				color: $grey-dark;
				@include clearfix;
				@include single-transition(opacity);
				&:hover {
					opacity: .7;
				}
			}
			img {
				float: left;
				width: 100px;
				@media #{$medium-up} {
					width: 200px;
				}
			}
		}
		ul {
			list-style-type: disc;
		}
		.inner-content {
			margin-left: 120px;
			@media #{$medium-up} {
				margin-left: 220px;
			}
		}
		h3 {
			margin-bottom: 10px;
			font-size: 18px;
		}
	}
	.grey-container {
		margin-bottom: 20px;
		background: $grey-lightest;
		border: 1px solid $grey-light;
		color: $grey-dark;
		h3 {
			margin: 0;
			padding: 20px;
			border-bottom: 1px solid $grey-light;
			color: $grey-dark;
			font-size: 20px;
			line-height: 1.2;
		}
		.grey-content {
			padding: 20px;
		}
		a {
			color: $blue-darker;
			text-decoration: underline;
		}
	}
}
