/*-------------------------------------------------------------------------------------------*/
/* Re-usable components */
/*-------------------------------------------------------------------------------------------*/

/* Search Box */
@mixin searchbox-style ($bgc: $blue-darkest, $bdc: $blue-darkest, $icon-color: $light-line, $placeholder-color: $grey, $text-color: $grey) {

	position: relative;
	width: 295px;
	padding: 5px 0 5px 5px; //we want the search box flush against the side
	@include clearfix;

	.search-input {
		float: left;
		width: 205px;
		height: 35px;
		line-height: 1.2;
		margin: 0;
		padding: 0 10px 0 35px;
		border-color: $bdc;
		border-radius: $global-radius;
		background-color: $bgc;
		color: $text-color;
		&::-webkit-input-placeholder {
			color: $placeholder-color;
		}
		&:-moz-placeholder {
		    color: $placeholder-color;
		    opacity: 1;
		}
		&::-moz-placeholder {
		    color: $placeholder-color;
		    opacity: 1;
		}
		&:-ms-input-placeholder {
		    color: $placeholder-color;
		}
	}
	.search-icon {
		position: absolute;
		left: 15px;
		top: 12px;
		color: $icon-color;
	}
	.search-button {
		float: right;
		width: 75px;
		line-height: 1.2;
		margin: 0 0 0 10px;
		border-radius: $global-radius;
	}

}


/* Link text with arrow on the left */
// include it in li or div, etc

@mixin left-arrow-link ($font-size: 12px) {
	line-height: 1.2;
	padding-left: $font-size;
	a {
		position: relative;
		font-size: $font-size;
		color: $blue;
		@include single-transition;
		&:hover {
			color: $blue;
			@include opacity(0.8);
		}
		&:before {
			position: absolute;
			left: $font-size * -1;
			top: $font-size / 6;
			padding-left: $font-size / 3;
			@include fa-icon();
			content: "\f0da";
		}
	}
	.link-style {
		position: relative;
		font-size: $font-size;
		color: $blue;
		&:before {
			position: absolute;
			left: $font-size * -1;
			top: $font-size / 6;
			padding-left: $font-size / 3;
			@include fa-icon();
			content: "\f0da";
		}
	}
}

/* Breadcrumbs */
// include it in ul

@mixin breadcrumb() {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
		a {
			text-decoration: underline;
		}
		&:last-of-type i {
			display: none;
		}
	}
}
